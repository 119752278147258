
.icon-bar {
    position: fixed;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }
  
  .icon-bar a:hover {
    background-color: #000;
  }
  
  .whatsapp {
    background: #25D366;
    color: white;
  }